.ActivityIcon {
  overflow: clip;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ActivityIcon.icon img {
  object-fit: cover;
}
