.Auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Auth p {
  font-style: italic;
}

.Auth form {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  text-align: center;
}

.Auth form label {
  font-size: 1.25rem;
  margin-bottom: 0.5em;
}

.Auth form input {
  padding: 0.5rem;
  width: 100%;
  /* It's in App.css as general styling for now */
  /*background-color: #3c3836;*/
  /*color: #fbf1c7;*/
  /*border-color: #a89984;*/
}
