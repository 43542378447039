.ActivityList {
}

.ActivityList-menubar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.ActivityList-menubar label span {
  vertical-align: middle;
  padding: 0.5em;
}

.ActivityList-toggle {
  vertical-align: middle;
}

/* Override colours for toggle */
.react-toggle-track {
  /*background-color: #4d4d4d;*/
  background-color: #3c3836;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  /*background-color: #000000;*/
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  /*background-color: #19ab27;*/
  background-color: #b8bb26;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  /*background-color: #128d15;*/
  background-color: #98971a;
}

.react-toggle-thumb {
  border: 1px solid #4d4d4d;
  background-color: #fafafa;
}

.react-toggle--checked .react-toggle-thumb {
  /*border-color: #19ab27;*/
  border-color: #b8bb26;
}

.react-toggle--focus .react-toggle-thumb {
  /*-webkit-box-shadow: 0px 0px 3px 2px #0099e0;*/
  /*-moz-box-shadow: 0px 0px 3px 2px #0099e0;*/
  /*box-shadow: 0px 0px 2px 3px #0099e0;*/
  -webkit-box-shadow: 0px 0px 3px 2px var(--focus-colour);
  -moz-box-shadow: 0px 0px 3px 2px var(--focus-colour);
  box-shadow: 0px 0px 2px 3px var(--focus-colour);
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  /*-webkit-box-shadow: 0px 0px 5px 5px #0099e0;*/
  /*-moz-box-shadow: 0px 0px 5px 5px #0099e0;*/
  /*box-shadow: 0px 0px 5px 5px #0099e0;*/
  -webkit-box-shadow: 0px 0px 5px 5px var(--focus-colour);
  -moz-box-shadow: 0px 0px 5px 5px var(--focus-colour);
  box-shadow: 0px 0px 5px 5px var(--focus-colour);
}
