.App {
  margin: 0 auto;
  /*max-width: 960px;*/
  max-width: 600px;
  min-height: 100%;
}

/* Based on sample CSS from docs */
.ariakit-combobox-wrapper {
  display: flex;
  flex-direction: column;
  /*gap: 0.5rem;*/
  /*border-radius: 0.5rem;*/
  /*background-color: hsl(204 3% 18%);*/
  /*padding: 1rem;*/
  /*box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.25), 0 1px 2px -1px rgb(0 0 0 / 0.1);*/
}

.ariakit-combobox-wrapper > label {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*gap: 0.5rem;*/
}

.ariakit-combobox {
  /*height: 2.5rem;*/
  /*width: 250px;*/
  /*border-radius: 0.375rem;*/
  /*border-style: none;*/
  /*background-color: hsl(204 3% 12%);*/
  /*padding-left: 1rem;*/
  /*padding-right: 1rem;*/
  /*font-size: 1rem;*/
  /*line-height: 1.5rem;*/
  /*color: hsl(204 20% 100%);*/
  /*box-shadow: inset 0 0 0 1px rgba(255 255 255 / 0.12),*/
  /*  inset 0 -1px 0 0 rgba(255 255 255 / 0.05),*/
  /*  inset 0 2px 5px 0 rgba(0 0 0 / 0.15);*/
}

.ariakit-combobox::placeholder {
  /*color: hsl(204 20% 100% / 46%);*/
}

.ariakit-combobox:hover {
  /*background-color: hsl(204 3% 10%);*/
}

.ariakit-combobox:focus-visible,
.ariakit-combobox[data-focus-visible] {
  /*outline: 2px solid hsl(204 100% 40%);*/
}

.ariakit-combobox-popover {
  position: relative;
  z-index: 50;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  overflow: auto;
  overscroll-behavior: contain;
  /*border-radius: 0.5rem;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
  /*border-color: hsl(204 3% 26%);*/
  /*background-color: hsl(204 3% 18%);*/
  background-color: #3c3836;
  padding: 0.5rem;
  /*color: hsl(204 20% 100%);*/
  /*outline: 2px solid transparent;*/
  /*outline-offset: 2px;*/
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.12))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.ariakit-combobox-item {
  display: flex;
  cursor: default;
  scroll-margin: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  /*outline: none !important;*/
}

.ariakit-combobox-item:hover {
  /*background-color: hsl(204 100% 40% / 0.25);*/
  background-color: #83a598;
}

.ariakit-combobox-item[data-active-item] {
  /*background-color: hsl(204 100% 40%);*/
  background-color: #458588;
  /*color: hsl(204 20% 100%);*/
}
