.ActivityRatingBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
}

.ActivityRatingBarBlock {
  border: none;
  background: none;
  /*width: 100%;*/
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  text-align: center;
  /*min-height: 64px;*/
  padding: 1em;
  color: inherit;
  font-size: 1rem;
  cursor: pointer;
}

.ActivityRatingBarBlock--no {
  background-color: #cc241d;
}

.ActivityRatingBarBlock--would {
  background-color: #d79921;
}

.ActivityRatingBarBlock--like {
  background-color: #458588;
}

.ActivityRatingBarBlock--love {
  background-color: #98971a;
}

.ActivityRatingBarBlock--no.ActivityRatingBarBlock--selected {
  background-color: #fb4934;
}

.ActivityRatingBarBlock--would.ActivityRatingBarBlock--selected {
  background-color: #fabd2f;
}

.ActivityRatingBarBlock--like.ActivityRatingBarBlock--selected {
  background-color: #83a598;
}

.ActivityRatingBarBlock--love.ActivityRatingBarBlock--selected {
  background-color: #b8bb26;
}

.ActivityRatingBarBlock--selected {
  color: #1d2021;
}
