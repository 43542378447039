.ActivityDisplay {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1em;
  min-height: 8em;
}

/*.ActivityDisplay {*/
/*  display: grid;*/
/*  width: 100%;*/
/*  grid-template-columns: 30% 1fr;*/
/*  grid-template-rows: 1fr 1fr;*/
/*  gap: 0px 0px;*/
/*  grid-template-areas:*/
/*    "icon name"*/
/*    "icon ratings";*/
/*  margin-bottom: 1em;*/
/*  min-height: 8em;*/
/*}*/

.ActivityDisplay-icon {
  /*grid-area: icon;*/
  flex-basis: 144px;
}

.ActivityDisplay-icon .ActivityIcon {
  /*padding: 28px;*/
  width: 100%;
  height: 100%;
}

.ActivityDisplay-icon .ActivityIcon.ActivityIcon--placeholder {
  /*padding: 28px;*/
  padding: 16px;
}

.ActivityDisplay-details {
  flex-grow: 1;
  /*flex-shrink: 1;*/
  flex-basis: 0;
}

.ActivityDisplay-name {
  padding: 1rem 2rem;
  /*grid-area: name;*/
  font-size: 2em;
  text-align: start;
  /*word-break: break-word;*/
  background-color: #3c3836;
}

.ActivityDisplay-ratings {
  /*grid-area: ratings;*/
}
