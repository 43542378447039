.ActivityCreate {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  width: 100%;
  --border-radius-top: 5px;
  --border-radius-bottom: var(--border-radius-top);
  margin-bottom: 2em;
}

/* Remove the border when the rating bar is added, as it lines up */
.ActivityCreate .ActivityCreate--combine {
  --border-radius-bottom: 0;
}

.ActivityCreate--name {
  display: flex;
  flex-direction: row;
}

.ActivityCreate--name input {
  padding: 1rem;
  font-size: 2em;
  width: 100%;
  border-radius: 0;
}

.ActivityCreate-combobox {
  width: 100%;
}

.ActivityCreate-combobox .ActivityIcon {
  height: 2em;
}

.ActivityCreate--name {
  --item-width: 8em;
}

.ActivityCreate--name button {
  border-radius: 0 var(--border-radius-top) var(--border-radius-bottom) 0;
  width: var(--item-width);
}

.ActivityTypeSelector {
  border-radius: var(--border-radius-top) 0 0 var(--border-radius-bottom);
  width: var(--item-width);
  /*border-top-right-radius: 0;*/
  /*border-bottom-right-radius: 0;*/
}

/*.ActivityCreate .ActivityRatingBar {*/
/*  height: 3em;*/
/*}*/

.ActivityCreateEdit-ratingbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 3em;
  transition: min-height var(--animation-duration-medium) ease-in-out;
  border-radius: 0 0 var(--border-radius-top) var(--border-radius-top);
  overflow: hidden;
}

.ActivityCreateEdit-ratingbar--guide {
  min-height: 8em;
}

/*.ActivityCreateEmpty {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*}*/

/*.ActivityTypeSelector {*/
/*!*  display: flex;*!*/
/*!*  justify-content: center;*!*/
/*!*  width: 100%;*!*/
/*!*}*!*/

/*!*.ActivityTypeSelector-type {*!*/
/*!*  padding: 1em;*!*/
/*!*}*!*/

/*!*.ActivityTypeSelector-type--selected {*!*/
/*!*  background-color: #b16286;*!*/
/*}*/
