*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  /*background-color: #141312;*/
  /*color: #efefef;*/
  background-color: #1d2021;
  color: #ebdbb2;
  margin: 0;
  padding: 32px 0;
  min-height: 100vh;
  line-height: 1.2;
  font-family: sans-serif;
}

#root {
  min-height: 100%;
  height: 100%;
  --animation-duration-medium: 0.1s;
  --focus-colour: #83a598;
}

@media (prefers-reduced-motion) {
  #root {
    --animation-duration-medium: 0s;
  }
}

body,
html {
  height: 100%;
}

input {
  background-color: #3c3836;
  color: #fbf1c7;
  border-color: #a89984;
}

*:focus-visible {
  outline: 3px solid var(--focus-colour);
}
