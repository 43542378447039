.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*text-align: center;*/
  height: 100%;
  justify-content: center;
}

.Home button {
  /*padding: 1em;*/
  /*!*font-size: 2em;*!*/
  padding: 0.5em;
  font-size: 1.2em;
}
